import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from 'styled-components';

import Layout from "../layouts";
import Seo from "../components/seo";

import BreakpointUp from "../components/Media/BreakpointUp"
import ProductCard from "../components/ProductCard"
import { BreadCrumb, Section,	SectionTitle,	SectionDescription } from '../components/Section'
import ArrowRightIcon from "../components/Icons/ArrowRightIcon";
import CtaSection from '../sections/CtaSection';

const HeroContainer = styled.div`
	border-top: 1px solid #D0D0D0;
	border-bottom: 1px solid #D0D0D0;
	padding: 40px 0;
	margin-bottom: 41px;
`

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  ${BreakpointUp.lg`
        margin:0 -20px;
    `}
`
const LeftSidbar = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  display:none;
  ${BreakpointUp.md`
  display:block;
  `}
  ${BreakpointUp.lg`
        padding:0 20px;    
        flex: 0 0 23%;
        max-width: 23%;
    `}
    strong {
    display: block;
    margin-bottom: 10px;
    color: #000;
  }
`

const ProductList = styled.div`
	position: relative;
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  order: 1;
  ${BreakpointUp.lg`
    padding:0 20px;
    flex: 0 0 77%;
    max-width: 77%;
    order:0;
  `}
`

const CategoryWrap = styled.div`
	border-bottom: 1px solid #D0D0D0;
	padding: 15px 0;
`

const CategoryItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 18px;
	line-height: 24px;
	color: ${props => props.active ? "#E43D3C": "#000000"};

	&:hover {
		cursor: pointer;
	}
`

const SubCategoryItem = styled.div`
	display: block;
	font-size: 14px;
	border: 1px solid #D0D0D0;
	border-radius: 30px;
	width: fit-content;
	padding: 7px 20px 8px;
	margin-top: 8px;
	color: ${props => props.active ? "#E43D3C": "#000000"};

	&:hover {
		cursor: pointer;
	}
`


const AllProducts = ({ data, location }) => {
	const categories = data.allContentfulCategory.edges;
	const subCategories = data.allContentfulSubCategory.edges;
	const products = data.allContentfulProduct.edges;
	const [currentCategory, setCurrentCategory] = useState('all');
	const [currentSubCategory, setCurrentSubCategory] = useState("all");
	const [currentProducts, setCurrentProducts] = useState(products);

	useEffect(() => {
		let currentProductsTemp = [];
		products.forEach(function(item) {
			if (item.node.category?.name === currentCategory) {
				currentProductsTemp.push(item);
			}
		})
		setCurrentSubCategory('all');
		setCurrentProducts(currentProductsTemp)
			
	}, [currentCategory, setCurrentCategory]);

	useEffect(() => {
		if (currentCategory === 'all') {
			setCurrentProducts(products);
		} else {
			if (currentSubCategory === 'all') {
				let currentProductsTemp = [];
				products.forEach(function(item) {
					if (item.node.category.name === currentCategory) {
						currentProductsTemp.push(item);
					}
				})
				setCurrentProducts(currentProductsTemp)
			} else {
				let currentProductsTemp = [];
				products.forEach(function(item) {
					if (item.node.category.name === currentCategory && item.node.subCategory?.name ===currentSubCategory) {
						currentProductsTemp.push(item);
					}
				})
				setCurrentProducts(currentProductsTemp)
			}
		}
	}, [currentSubCategory, setCurrentSubCategory]);

	const handleCategory = (name) => {
		if (name === currentCategory) {
			setCurrentSubCategory('all');
		} else {
			setCurrentSubCategory('all');
			setCurrentCategory(name);
		}
	}
	return(
		<Layout footerCta location={location}>
			<Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
			<Seo title="All Products" />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>All Products</span>
				</div>
			</BreadCrumb>
			<Section pb="0" pt="0" mpt="0" mpb="0">
				<HeroContainer className="container">
					<SectionTitle>Product Gallery</SectionTitle>
					<SectionDescription maxWidth="1120px">
						Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book It has survived not only five centuries, but also the leap into electronic typesetting.
					</SectionDescription>
				</HeroContainer>
				<div className="container">
					<MainPanel>
						<LeftSidbar>
							<div className="isSticky">
								{
									categories.map((category, i) => {
										return(
											<CategoryWrap>
												<CategoryItem
													onClick={()=> handleCategory(category.node.name)}
													key={i}
													active={category.node.name===currentCategory?true:false}
												>
													{category.node.name} <ArrowRightIcon fill="#E43D3C" />
												</CategoryItem>
												{category.node.name===currentCategory && subCategories.map((item, i) =>{
													if (item.node.category.name === currentCategory) {
														return(
															<SubCategoryItem
																onClick={()=>setCurrentSubCategory(item.node.name)}
																active={item.node.name===currentSubCategory?true:false}
															>
																{item.node.name}
															</SubCategoryItem>
														)
													} else {
														return true;
													}
												})}
											</CategoryWrap>
										)
									})
								}
							</div>
						</LeftSidbar>
						<ProductList>
						{
							currentProducts.map((item, i) => (
								<ProductCard data={item.node} key={`product-${i}`} />
							))
						}
						</ProductList>
					</MainPanel>
				</div>
			</Section>
			<CtaSection />
		</Layout>
	)
}

export default AllProducts

export const query = graphql`
  query AllProductsPageQuery {
  	allContentfulProduct {
      edges {
        node {
          category {
            name
          }
          subCategory {
          	name
          }
          name
          sku
          width
          length
          height
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          startingPrice
          downPayment
          url
        }
      }
    }
    allContentfulCategory {
	    edges {
	      node {
	        name
	      }
	    }
	  }
	  allContentfulSubCategory {
	    edges {
	      node {
	        category {
	          name
	        }
	        name
	      }
	    }
	  }
  }
`